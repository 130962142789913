







































import { InputSetups } from '@/mixins/input-setups'
import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import EntertainmentHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/EntertainmentHandlerModule/EntertainmentHandlerModuleBuilder'
import SelectOptionsMixin from '@/mixins/SelectOptionsMixin'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'

import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SelectInput
  },
})
export default class EntertainmentHandlerModuleView extends Mixins(InputSetups, ModuleBindings, SelectOptionsMixin) {
  @VModel() module!: EntertainmentHandlerModuleBuilder
}
